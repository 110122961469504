.container{
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 20px;
  border: 1px solid #868686;
  border-radius: 5px;
  margin-top: 6px;
}

.content{
  display: flex;
  gap: 3rem;
}
