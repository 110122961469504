@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

body {
  background-color: #F3F4F6 !important;
}

#map { height: 700px; }
